//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable } from '@/components'
import { Empty } from 'ant-design-vue'
import { getOrgTree } from '@/api/modular/system/orgManage'
import { getUserPage } from '@/api/modular/system/userManage'

export default {
  components: {
    STable
  },

  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '賬號',
          dataIndex: 'account'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '手機',
          dataIndex: 'phone'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return getUserPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      dataSource: [],
      orgTree: [],
      selectedRowKeys: [],
      selectedRows: [],
      defaultExpandedKeys: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        key: 'id'
      },
      // 彈出框參數
      visible: false,
      confirmLoading: false
    }
  },

  methods: {
    open () {
      this.visible = true
      this.getOrgTree()
    },
    /**
     * 獲取到機構樹，展開頂級下樹節點，考慮到後期數據量變大，不建議全部展開
     */
    getOrgTree () {
      getOrgTree(Object.assign(this.queryParam)).then(res => {
        this.treeLoading = false
        if (!res.success) {
          return
        }
        this.orgTree = res.data
        for (var item of res.data) {
          // eslint-disable-next-line eqeqeq
          if (item.parentId == 0) {
            this.defaultExpandedKeys.push(item.id)
          }
        }
      })
    },
    /**
     * 點擊左側機構樹查詢列表
     */
    handleClick (e) {
      this.queryParam = {
        'sysEmpParam.orgId': e.toString()
      }
      this.$refs.table.refresh(true)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleSubmit () {
      this.visible = false
    },
    handleCancel () {
      this.orgTree = []
      this.queryParam = {}
      this.loadData = parameter => { return [] }
      this.visible = false
    }
  }

}
